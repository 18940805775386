<template>
  <div id="nav">
    <van-tabbar route style="border: 1px solid #ccc" active-color="#001f90">
      <van-tabbar-item replace to="/" icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item replace to="/server" icon="search">服务</van-tabbar-item>
      <van-tabbar-item replace to="/mine" icon="friends-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from "vant";

export default {
  name: "Menu",
  components: {
    VanTabbar: Tabbar,
    VanTabbarItem: TabbarItem
  }
};
</script>
<style>
#nav {
  width: 100%;
  font-size: 32px !important;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}
</style>
