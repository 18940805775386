<template>
  <div class="wrapper">
    <van-row type="flex" justify="space-between" align="center" class="top">
      <van-col span="8" class="avatar">
        <img
          v-if="img"
          :src="imgurl"
          style="width: 64px; height: 64px"
          alt=""
        />
        <van-icon v-else name="user-o" size="64px" />
      </van-col>
      <van-col span="12">{{ $store.state.user.nickname }}</van-col>
      <van-col span="4" class="setting">
        <van-icon
          class="icon"
          name="setting-o"
          size="24px"
          color="#FFF"
          @click="toSettings"
        />
      </van-col>
    </van-row>
    <van-cell-group>
      <van-cell title="地块管理" size="large" is-link to="/farmlandlist" />
      <van-cell
        title="圈舍管理"
        size="large"
        is-link
        to="/mine/Enclosure"
      ></van-cell>
      <van-cell
        title="种植档案"
        size="large"
        is-link
        to="/mine/archives"
      ></van-cell>
      <van-cell title="消息中心" size="large" is-link to="/message" />
      <van-cell
        title="收成记录"
        size="large"
        is-link
        to="/mine/harvestRecords"
      />
      <!-- <van-cell title="版本更新" size="large" is-link to="/" />
      <van-cell title="新手指导" size="large" is-link to="/" />
      <van-cell title="联系客服" size="large" is-link to="/" /> -->
    </van-cell-group>
    <div class="exit-button">
      <van-button
        type="primary"
        size="small"
        block
        :color="themeColor"
        @click="exit"
        >退出</van-button
      >
    </div>
    <nav-menu></nav-menu>
  </div>
</template>
<script>
import Menu from "@/views/menu/menu";
import { users } from "../../service/user";
import { Col, Row, Icon, Cell, CellGroup, Button } from "vant";
export default {
  name: "MineIndex",
  data() {
    return {
      themeColor: "#001f90",
      imgurl: "",
      img: "",
      flag: true
    };
  },
  components: {
    "nav-menu": Menu,
    VanCol: Col,
    VanRow: Row,
    VanIcon: Icon,
    VanCell: Cell,
    VanCellGroup: CellGroup,
    VanButton: Button
  },
  mounted() {
    if (this.flag === true) {
      const id = this.$store.state.user.id;
      users(id).then((res) => {
        this.img = res.data.path;
        this.imgurl = "http://110.87.103.58:54321/" + res.data.path;
      });
      this.flag = false;
    }
  },
  methods: {
    toSettings() {
      this.$router.push("/mine/settings");
    },
    exit() {
      this.$store.commit("exit");
      this.$router.push("/login");
    }
  }
};
</script>
<style lang='scss' scoped>
$color: #001f90;
.wrapper {
  .top {
    background: $color;
    padding: 48px 0;
    color: #fff;
    .avatar {
      text-align: center;
    }
    .setting {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .exit-button {
    margin: 8px;
    background: $color;
  }
}
</style>
